import { Button, Form, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import axios from "./api/axios";

const ModalAggiungiProdotto = ({
  modalAggiungi,
  setModalAggiungi,
  nomeProdotto,
  setNomeProdotto,
  descrizione,
  setDescrizione,
  prezzo,
  setPrezzo,
  immagine,
  setImmagine,
  lstImmagini,
  setLstImmagini,
}) => {
  async function aggiungiProdotto() {
    const data = {
      NomeProdotto: nomeProdotto,
      Descrizione: descrizione,
      Prezzo: prezzo,
      Immagine: immagine,
      LstImmagini: lstImmagini,
    };
    await axios.get(`api/Main/`, data).then((res) => {});
  }
  return (
    <div>
      <Modal
        open={modalAggiungi}
        onOk={() => setModalAggiungi(false)}
        onCancel={() => setModalAggiungi(false)}
        footer={
          <>
            <Button
              type='primary'
              onClick={() => {
                aggiungiProdotto();
              }}
            >
              Aggiungi prodotto
            </Button>
            <Button onClick={() => setModalAggiungi(false)}>Indietro</Button>
          </>
        }
      >
        <Form>
          <Form.Item
            label='Nome Prodotto'
            rules={[
              { required: true, message: "Please input the product name" },
            ]}
          >
            <Input
              value={nomeProdotto}
              onChange={(e) => setNomeProdotto(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label='Descrizione'
            rules={[
              { required: true, message: "Please input the description" },
            ]}
          >
            <TextArea
              value={descrizione}
              onChange={(e) => setDescrizione(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label='Prezzo'
            rules={[{ required: true, message: "Please input the price" }]}
          >
            <Input
              type='number'
              value={prezzo}
              onChange={(e) => setPrezzo(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label='Immagine'
            rules={[{ required: true, message: "Please input the image url" }]}
          >
            <Input
              value={immagine}
              onChange={(e) => setImmagine(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label='Lista Immagini'
            rules={[
              {
                required: true,
                message: "Please input the list of images urls",
              },
            ]}
          >
            <Input.TextArea
              value={lstImmagini}
              onChange={(e) => setLstImmagini(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ModalAggiungiProdotto;
