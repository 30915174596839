import logo from "./logo.svg";
import "./App.css";
import emailjs from "@emailjs/browser";
import { useRef, useState, createContext } from "react";
import $ from "jquery";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Row,
  Space,
  notification,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import axios from "./api/axios";
import RegisterModal from "./RegisterModal";
import Home from "./Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Amministratore from "./Amministratore";
import AreaUtente from "./AreaUtente";
import ThankYou from "./ThankYou";
import Login from "./Login";

export const MainContext = createContext();
export const ContactUs = () => {
  const form = useRef();
};

function App() {
  const [datiUtente, setDatiUtente] = useState();
  const [auth, setAuth] = useState(false);
  return (
    <MainContext.Provider value={{ datiUtente, setDatiUtente }}>
      <BrowserRouter>
        <Routes>
          {auth ? (
            <>
              <Route
                path='/amministratore'
                element={<Amministratore />}
              ></Route>
              <Route path='/' element={<Home />}></Route>
              <Route path='/utente' element={<AreaUtente />}></Route>
              <Route path='/thank-you' element={<ThankYou />}></Route>
            </>
          ) : (
            <>
              <Route path='/' element={<Home />}></Route>
              <Route
                path='/login'
                element={<Login setAuth={setAuth} />}
              ></Route>
            </>
          )}
        </Routes>
      </BrowserRouter>
    </MainContext.Provider>
  );
}

export default App;
