import { Button, Input, notification } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = ({ setAuth }) => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();
  async function onLogin() {
    const p = "mypsw";
    const u = "myusr";
    if (password === p && username === u) {
      setAuth(true);
      navigate("/amministratore");
    } else {
      api.info({ message: "Username o password non corretti" });
    }
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {contextHolder}
      <div>
        <Input
          type='text'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        ></Input>
        <Input
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></Input>
        <Button onClick={onLogin}>Login</Button>
      </div>
    </div>
  );
};

export default Login;
