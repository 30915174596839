import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Row,
  Space,
  notification,
} from "antd";
import React, { useState, useContext } from "react";
import axios from "./api/axios";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const RegisterModal = ({ open, setOpen, setModal2Open }) => {
  const [email, setEmail] = useState("");
  const [confermaEmail, setConfermaEmail] = useState("");
  const [password, setPassword] = useState("");
  const [api, contextHolder] = notification.useNotification();

  async function register() {
    await axios
      .post(`api/Main/${email}/${password}`)
      .then((res) => {
        let data = res.data;
        console.log("data", data);
        let foundUser = data.email;
        if (foundUser === email) {
          console.log("data Inside Login", data);
          api.success({
            message: `Login effettuato con successo`,
          });
        } else {
          api.info({
            message: `${data}`,
          });
        }
      })
      .catch((error) =>
        api.error({
          message: `${error}`,
        })
      );
  }
  return (
    <div>
      <Modal
        // title="Accedi all'area personale"
        // centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[""]}
        width={"350px"}
      >
        <Row justify='center' style={{ marginBottom: "20px" }}>
          <h4>Registrati </h4>
        </Row>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name='Email'
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Email'
            />
          </Form.Item>
          <Form.Item
            name='Email'
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              value={confermaEmail}
              onChange={(e) => setConfermaEmail(e.target.value)}
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Conferma email'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Password'
            />
          </Form.Item>

          <Row justify='center'>
            <Space justifyContent='center'>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  onClick={() => register()}
                >
                  Registrati
                </Button>
                O{" "}
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  href=''
                  onClick={() => {
                    setOpen(false);
                    setModal2Open(true);
                  }}
                >
                  Login!
                </span>
              </Form.Item>
            </Space>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default RegisterModal;
