import React, { useEffect, useState } from "react";
import { Button, Layout, Space, Table, Typography, notification } from "antd";
import UtenteModal from "./UtenteModal";
import { render } from "@testing-library/react";
import axios from "./api/axios";
import ModalAggiungiProdotto from "./ModalAggiungiProdotto";
const { Title } = Typography;
const { Sider, Content } = Layout;

const Amministratore = () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [dataSchede, setDataSchede] = React.useState([]);
  const [utenti, setUtenti] = React.useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [nomeProdotto, setNomeProdotto] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [prezzo, setPrezzo] = useState("");
  const [immagine, setImmagine] = useState("");
  const [lstImmagini, setLstImmagini] = useState([]);
  const [modalAggiungi, setModalAggiungi] = useState(false);
  useEffect(() => {
    getUtenti();
  }, []);
  const handleCancel = () => {
    setModalVisible(false);
  };
  async function getUtenti() {
    // await axiosS.get("https://asptrainerweb.bsite.net/api/Main").then((res) => {
    await axios.get("api/Main").then((res) => {
      let data = res.data;
      setUtenti(data);
    });
  }

  async function getSchedeUtente(pkUtente) {
    await axios
      .get(`api/Main/Schede/${pkUtente}`)
      .then((res) => {
        let data = res.data;
        console.log("dataSchede", data);
        let foundUser = data[0].FK_Utente;

        if (foundUser === pkUtente) {
          api.success({
            message: `Liste schede caricate con successo`,
          });
          let schedeValide = data.filter(
            (e) => e.Eliminata === false || e.Eliminata === null
          );
          setDataSchede(schedeValide);
        } else {
          api.info({
            message: "L'utente non ha schede",
            description: `${data}`,
          });
        }
      })
      .catch((error) =>
        api.error({
          message: "L'utente non ha schede ",
          description: `${error}`,
        })
      );
  }
  async function eliminaUtente(pkUtente) {
    await axios
      .post(`api/Main/elimina/${pkUtente}`)
      .then((res) => {
        let data = res.data;
        console.log("data", data);
        if (data === "Utente eliminato") {
          api.success({
            message: `Utente eliminato con successo`,
          });
        } else {
          api.info({
            message: `${data}`,
          });
        }
      })
      .catch((error) =>
        api.error({
          message: `${error}`,
        })
      );
  }
  const columns = [
    {
      title: "Nome",
      dataIndex: "Nome",
      key: "nome",
    },
    {
      title: "Cognome",
      dataIndex: "Cognome",
      key: "cognome",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "email",
    },
    {
      title: "Azioni",
      dataIndex: "PK_Utente",
      key: "PK_Utente",
      render: (e) => {
        return (
          <Space>
            <Button
              type='primary'
              onClick={() => {
                setModalVisible(true);
                getSchedeUtente(e);
              }}
            >
              Schede
            </Button>
            <Button type='primary'>Modifica</Button>
            <Button
              onClick={() => {
                eliminaUtente(e);
              }}
            >
              Rimuovi
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {contextHolder}
      <Sider width={200} style={{ background: "#fff" }}>
        {/* Sidebar content */}
        <div>User Information</div>
        {/* Add your user information here */}
      </Sider>
      <Content style={{ padding: "3rem 2rem" }}>
        {/* Table content */}
        <Title level={2}>Utenti registrati</Title>
        <Table columns={columns} dataSource={utenti} />
      </Content>
      <UtenteModal
        utenti={utenti}
        data={dataSchede}
        visible={modalVisible}
        onCancel={handleCancel}
      />
      <ModalAggiungiProdotto
        modalAggiungi={modalAggiungi}
        setModalAggiungi={setModalAggiungi}
        nomeProdotto={nomeProdotto}
        descrizione={descrizione}
        prezzo={prezzo}
        immagine={immagine}
        lstImmagini={lstImmagini}
        setNomeProdotto={setNomeProdotto}
        setDescrizione={setDescrizione}
        setPrezzo={setPrezzo}
        setImmagine={setImmagine}
        setLstImmagini={setLstImmagini}
      ></ModalAggiungiProdotto>
    </Layout>
  );
};

export default Amministratore;
