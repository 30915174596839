import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  notification,
} from "antd";
import axios from "./api/axios";

const { Column } = Table;

const UtenteModal = ({ data, utenti, visible, onCancel }) => {
  const [modificaSchedaModal, setModificaSchedaModal] = useState(false);
  const [nomeScheda, setNomeScheda] = useState("");
  const [pkScheda, setPkScheda] = useState("");
  const [link, setLink] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [foundUser, setFoundUser] = useState();

  useEffect(() => {
    setFoundUser(utenti?.find((d) => d?.PK_Utente === data[0]?.FK_Utente));
  }, [visible]);
  useEffect(() => {
    let schedaFound = data?.find((d) => d?.PK_Scheda === pkScheda);
    setNomeScheda(schedaFound?.NomeScheda);
    setLink(schedaFound?.Link);
  }, [modificaSchedaModal, visible]);

  const userData = {
    nome: foundUser?.Nome,
    cognome: foundUser?.Cognome,
    eta: foundUser?.Eta,
    altezza: foundUser?.Altezza,
    sesso: foundUser?.Sesso,
    peso: foundUser?.Peso,
    cellulare: foundUser?.Cellulare,
    email: foundUser?.Email,
    social: foundUser?.Social,
  };

  async function modificaScheda(pkScheda) {
    await axios
      .put(`api/Main/Schede/${pkScheda}`)
      .then((res) => {
        let data = res.data;
        let foundUser = data[0].FK_Utente;

        api.success({
          message: `Liste schede caricate con successo`,
        });
        // if (foundUser === pkUtente) {

        // } else {
        //   api.info({
        //     message: `${data}`,
        //   });
        // }
      })
      .catch((error) =>
        api.error({
          message: `${error}`,
        })
      );
  }
  const columns = [
    {
      title: "Nome scheda",
      dataIndex: "NomeScheda",
      key: "NomeScheda",
    },
    {
      title: "Link",
      dataIndex: "Link",
      key: "Link",
    },
    {
      title: "Data Inserimento",
      dataIndex: "DataInserimento",
      key: "DataInserimento",
    },
    {
      title: "Azioni ",
      dataIndex: "PK_Scheda",
      key: "PK_Scheda",
      render: (e) => {
        return (
          <Space>
            <Button
              type='primary'
              onClick={() => {
                setModificaSchedaModal(true);
                setPkScheda(e);
              }}
            >
              Modifica
            </Button>
            <Button
              onClick={() => {
                // eliminaUtente(e);
              }}
            >
              Rimuovi
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Modal
      title='Informazioni Utente'
      open={visible}
      onCancel={onCancel}
      footer={null}
      width='100%'
      bodyStyle={{ minHeight: "400px" }}
    >
      {/* Upper part */}
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}
      >
        <Space direction='horizontal'>
          {/* Rounded image */}
          <Avatar size='large'>
            {/* <img
          src='/path/to/image.jpg'
          alt='Profile'
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginRight: "16px",
          }}
        /> */}
          </Avatar>
          {/* User information */}
          <Row>
            <Space size='large'>
              <Col>
                <p>Nome: {userData.nome}</p>
                <p>Cognome: {userData.cognome}</p>
              </Col>
              <Col>
                <p>Età: {userData.eta}</p>
                <p>Sesso: {userData.sesso}</p>
              </Col>
              <Col>
                <p>Peso: {userData.peso}</p>
                <p>Cellulare: {userData.cellulare}</p>
              </Col>
              <Col>
                <p>Altezza: {userData.altezza}</p>
                <p>Peso: {userData.peso}</p>
              </Col>
              <Col>
                <p>Email: {userData.email}</p>
                <p>Social: {userData.social}</p>
              </Col>
            </Space>
          </Row>
        </Space>
      </div>

      {/* Down part - table */}
      <Table
        dataSource={data ? data : null}
        pagination={false}
        columns={columns}
      >
        {/* <Column title='Nome scheda' dataIndex='NomeScheda' key='NomeScheda' />
        <Column title='Link' dataIndex='Link' key='Link' />
        <Column
          title='Data Inserimento'
          dataIndex='DataInserimento'
          key='DataInserimento'
        />
        <Column
          title='Azioni'
          dataIndex='azioni'
          key='azioni'
          render={(e) => {
            return (
              <Space>
                <Button
                  type='primary'
                  onClick={() => {
                    modificaScheda(e);
                  }}
                >
                  Modifica
                </Button>
                <Button
                  onClick={() => {
                    // eliminaUtente(e);
                  }}
                >
                  Rimuovi
                </Button>
              </Space>
            );
          }}
        /> */}
      </Table>
      <Modal
        title='Modifica Scheda'
        centered
        onCancel={() => setModificaSchedaModal(false)}
        open={modificaSchedaModal}
        footer={
          <>
            <Button type='primary'>Salva</Button>
            <Button onClick={() => setModificaSchedaModal(false)}>
              Indietro
            </Button>
          </>
        }
      >
        <Form.Item label='Nome scheda'>
          <Input
            value={nomeScheda}
            onChange={(e) => setNomeScheda(e.target.value)}
          ></Input>
        </Form.Item>
        <Form.Item label='Link scheda'>
          <Input value={link} onChange={(e) => setLink(e.target.value)}></Input>
        </Form.Item>
      </Modal>
    </Modal>
  );
};

export default UtenteModal;
