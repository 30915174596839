import axios from "axios";
//for localhost
const path = "https://localhost:7246";
// const path = "https://asptrainerweb.bsite.net/";
//for publish
//  const path = window.location.pathname;
// process.env.REACT_APP_NOT_SECRET_CODE;
export default axios.create({
  baseURL: path,
});
