import { Button, Result, Row } from "antd";
import React, { useEffect } from "react";
import emailjs from "@emailjs/browser";
const ThankYou = () => {
  useEffect(() => {
    sendEmail();
  }, []);
  async function sendEmail() {
    let dati = localStorage.getItem("datiScheda");
    console.log("dati", dati);

    // let nome = document.getElementById("nome").value;
    // const cognome = document.getElementById("cognome").value;
    // var eta = document.getElementById("eta").value;
    // var sesso = document.getElementById("sesso").value;
    // var altezza = document.getElementById("altezza").value;
    // var peso = document.getElementById("peso").value;
    // var obiettivo = document.getElementById("obiettivo").value;
    // var email = document.getElementById("email").value;
    // var livello = document.getElementById("livello").value;
    // var infortuniOproblemi =
    //   document.getElementById("infortuniOproblemi").value;
    // var doveAlleni = document.getElementById("doveAlleni").value;
    // var tempoDisposizione = document.getElementById("tempoDisposizione").value;
    // var giorniSettimana = document.getElementById("giorniSettimana").value;
    if (dati === "" || dati === undefined) {
      return false;
    } else {
      const datiScheda = JSON.parse(dati);
      console.log("dati parsed", datiScheda);
      const params = {
        service_id: "service_w6so1ra",
        template_id: "template_gasrmoc",
        user_id: "XfiKY2EhPk1cA_s4F",
        template_params: {
          //  to_name: to,
          from_name: "All data here",
          //  subject: subject,
          message: `
     nome = ${datiScheda?.nome},
     cognome = ${datiScheda?.cognome},
     eta = ${datiScheda?.eta},
     sesso = ${datiScheda?.sesso},
     altezza = ${datiScheda?.altezza},
     peso = ${datiScheda?.peso},
     obiettivo = ${datiScheda?.obiettivo},
     email = ${datiScheda?.email},
     livello = ${datiScheda?.livello},
     infortuniOproblemi = ${datiScheda?.infortuniOproblemi},
     doveAlleni = ${datiScheda?.doveAlleni},
     tempoDisposizione = ${datiScheda?.tempoDisposizione},
     giorniSettimana = ${datiScheda?.giorniSettimana},
    `,
        },
      };
      await emailjs
        .send(
          params.service_id,
          params.template_id,
          params.template_params,
          params.user_id
        )
        .then(
          function (response) {
            let modal = document.getElementById("#exampleModal");
            window.$("#exampleModal").modal("hide");
            //   api.success({
            //     message: "Dati inviati con successo",
            //     description:
            //       "Riceverai una mail non appena la tua scheda sarà pronta.",

            //   });
            //   cleanFormValues();
            console.log("Email successfully sent!", response.status);
          },
          function (error) {
            console.error("Error:", error);
          }
        );
    }
  }

  return (
    <div>
      <Row justify={"center"} align={"middle"} style={{ height: "100vh" }}>
        <Result
          status='success'
          title='Grazie per il tuo acquisto!'
          subTitle='A breve riceverai la tua nuova scheda'
          extra={[
            <Button
              className='checkout'
              type='primary'
              key='console'
              onClick={() => {
                localStorage.removeItem("datiScheda");
                window.location.replace("/");
              }}
            >
              Ritorna alla homepage
            </Button>,
            //   <Button key='buy'>Buy Again</Button>,
          ]}
        />
      </Row>
    </div>
  );
};

export default ThankYou;
