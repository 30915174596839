import React from "react";
import { Card, Col, Row } from "antd";
import "./CardSection.css";
import womanpic from "../images/tannedwoman-removebg-preview.png";
import alimentazione from "../images/icon4sezione/alimentazione.png";
import allenamento from "../images/icon4sezione/allenamento.png";
import bicep from "../images/icon4sezione/bicep.png";
import sleepBed from "../images/icon4sezione/sleepBed.png";
// import "antd/dist/antd.css";
const CardSection = () => {
  const cardData = [
    {
      image: allenamento,
      title: "Allenamento",

      // description: "This is card 1",
    },
    {
      image: alimentazione,
      title: "Alimentazione",
      // // description: "This is card 2",
    },
    {
      image: sleepBed,
      title: "Riposo",
      // description: "This is card 3",
    },
    {
      image: bicep,
      title: "Costanza",
      // description: "This is card 4",
    },
  ];

  return (
    <div>
      <Row gutter={[16, 16]}>
        {cardData.map((data, index) => (
          <Col
            span={12}
            key={index}
            style={{ background: "trasparent", backgroundColor: "trasparent" }}
          >
            <Card
              style={{
                background: "trasparent",
                backgroundColor: "trasparent",
              }}
              bodyStyle={{
                background:
                  "linear-gradient(45deg, #f74b21, #fd6f01, #fbb000) ",
                // clipPath: "polygon(100% 100%, 85% 100%, 100% 65%)",
                color: "black",
                zIndex: -10,
              }}
              // cover={

              // }
              title={data.title}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <img alt='example' src={data.image} className='card-image' />
              </div>
              {data.description}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default CardSection;
